function datepickerFormatDate(date) {
    if (!date || isNaN(date.getTime())) return null;
    return date.toISOString().split('T')[0];
}

$(document).on('load turbolinks:load', function() {
    $('.order-runtime-datepicker').each(function () {
        const $this = $(this);
        let defaultDate;
        if ($this.val()) {
            const inputDate = new Date($this.val());
            defaultDate = isNaN(inputDate.getTime()) ? null : datepickerFormatDate(inputDate);
        } else {
            const today = new Date();
            const fallbackDate = new Date(today.setMonth(today.getMonth() + 3));
            defaultDate = $this.hasClass('start_date') || $this.hasClass('no_default_fallback_date') ? null : datepickerFormatDate(fallbackDate);
        }
        $this.datetimepicker({
            defaultDate: defaultDate,
            minDate: ($this.val() && $this.hasClass('expiration_date')) ? defaultDate : datepickerFormatDate(new Date()),
            locale: "<%= I18n.locale.to_s %>",
            icons: {
                time: 'fa fa-clock',
                date: 'fa fa-calendar',
                up: 'fa fa-arrow-up',
                down: 'fa fa-arrow-down',
                previous: 'fa fa-chevron-left',
                next: 'fa fa-chevron-right',
                today: 'fa fa-calendar-check-o',
                clear: 'fa fa-trash',
                close: 'fa fa-times',
            },
        });
    });
});
